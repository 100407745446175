import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
// always log site-version (for debugging purposes)
console.log(
  '%c' +
      '------------------------------------------------\nSinterklaasjournaal ' +
      `${new Date().getFullYear()}` +
      ' | version: ' +
      `${process.env.REACT_APP_VERSION}`
);

// load site type before starting React
let siteType = 'pre';
                
if ( Date.now() > 1699895100000 ) {
  siteType = 'full';
  console.log('date criterium is met and site is set to full, the date now is:' + Date.now());
} else {
  console.log('It is not yet time for the full site');
}

if( window.config && window.config.siteType !== undefined) {
  siteType = window.config.siteType;
  console.log("siteType is available in window and set to: " + window.config.siteType);
}


  // check environment variables directly to prevent importing all stages
  if (siteType === 'pre' || siteType === 'post') {
      //pre site
      import('./sites/PrePostSite/PrePostSite')
          .then((v) => v.default)
          .then((Comp) => root.render(<Comp type={siteType} />));
  } else {
      //full site
      import('./sites/FullSite/FullSite')
          .then((v) => v.default)
          .then((Comp) => root.render(<Comp type={siteType} />));
  }

// unregister service worker
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
        registration.unregister();
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
